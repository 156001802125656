.btn-cmh-color {
  background-color: #1B9CFC;
  color: #ffffff;
  margin: 2px;
}

.btn-cmh-color:hover{
  color: #ffffff;
  box-shadow: 0 0 30px rgba(33,33,33,.2);
}

.btn-cmh-danger {
  background-color: #f44336;
  color: #ffffff;
  margin: 2px;
}

.btn-cmh-danger:hover {
  color: #ffffff;
  box-shadow: 0 0 30px rgba(33,33,33,.2);
}

.btn-cmh-warning {
  background-color: #ff9800;
  color: #ffffff;
  margin: 2px;
}

.btn-cmh-warning:hover {
  color: #ffffff;
  box-shadow: 0 0 30px rgba(33,33,33,.2);
}

.card-body-chm-color {
  background-color: #1B9CFC;
  color: #ffffff;
}

.leaflet-container {
  height: 100vh;
}

@media print { html, body { height: initial !important; overflow: initial !important; }}
