.buttonHeaderStyle{
    background-color: #1F8AFF !important;
    color: white !important;
    margin: 5em 2em !important;
    text-transform: none !important;
}

.backdropStyle{
    z-index: 9999 !important;
    color: #fff
}

.titleBox{
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    color: white;
    background-color: #303F9F;
    height: 52px;
}

.titleDialog{
    flex: 0 0 auto !important;
    margin: 0 !important;
    padding: 10px 25px !important;
}

.cardTitle{
    position: absolute;
    z-index: 1;
    font-size: 1.0rem !important;
    color: #3f51b5 !important;
    margin: -27px 0 0 0 !important;
    font-weight: bold !important;
}

.titleExpandPanel{
    font-weight: bold !important;
}

.titleExpandPanel:hover{
    color: #5c6bc0;
}

.titleTextExpandPanel{
    font-weight: bold !important;
}

.tdItem {
    text-align: center;
    vertical-align: middle;
}

.fabGridExtend {
    position: fixed  !important;
    bottom: 6rem;
    right: 2rem;
    transition-duration: 500ms !important;
}

.FormItem {
    width: '100%',
}