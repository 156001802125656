.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.trix-button-row {
  justify-content: flex-end !important;
  align-items: center !important;
}
.trix-toolbar .trix-button-group {
  margin-bottom: 0 !important;
}